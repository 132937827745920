import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { catchError, map, of } from 'rxjs';

export const userActiveGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  return inject(AuthService)
    .getCurrentUser()
    .pipe(
      map((res) => {
        if (res.signInDetails.loginId) {
          router.navigate(['content-management']);
          return false;
        }
        
        return true;
      }),
      catchError(() => {
        return of(true);
      })
    );
};
