import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { catchError, map, of } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  return inject(AuthService)
    .isAuthenticated()
    .pipe(
      map((authenticated) => {
        if (!authenticated) {
          router.navigate(['auth']);
          return false;
        }

        return true;
      }),
      catchError(() => {
        router.navigate(['auth']);
        return of(false);
      })
    );
};
