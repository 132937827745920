import { Component } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonDirective } from '@app/shared/directives';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  saxHome2Outline,
  saxMessageAddOutline,
  saxVideoVerticalOutline,
  saxVideoTickOutline,
  saxBookOutline,
  saxAwardOutline,
  saxCodeOutline,
} from '@ng-icons/iconsax/outline';

@Component({
  selector: 'app-menu-boost',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    ButtonDirective,
    TranslateModule,
    NgIconComponent,
  ],
  templateUrl: './menu-boost.component.html',
  styleUrl: './menu-boost.component.scss',
  viewProviders: [
    provideIcons({
      saxHome2Outline,
      saxMessageAddOutline,
      saxVideoVerticalOutline,
      saxVideoTickOutline,
      saxBookOutline,
      saxAwardOutline,
      saxCodeOutline,
    }),
  ],
})
export class MenuBoostComponent {
  constructor(public route: Router) {}
  public boostMenus = [
    // {
    //   icon: 'saxHome2Outline',
    //   text: 'menu-boost.dashboard',
    //   url: '/content-management/dashboards',
    // },
    {
      icon: 'saxMessageAddOutline',
      text: 'menu-boost.positive-message',
      url: '/content-management/positive-messages',
    },
    {
      icon: 'saxVideoVerticalOutline',
      text: 'menu-boost.hack',
      url: '/content-management/hacks',
    },
    {
      icon: 'saxVideoTickOutline',
      text: 'menu-boost.insight',
      url: '/content-management/insights',
    },
    {
      icon: 'saxBookOutline',
      text: 'menu-boost.challenge',
      url: '/content-management/challenges',
    },
    {
      icon: 'saxAwardOutline',
      text: 'menu-boost.milestone',
      url: '/content-management/milestones',
    },
    // {
    //   icon: 'saxCodeOutline',
    //   text: 'menu-boost.logs',
    //   url: '/content-management/logs',
    // },
  ];

  public isActive(route: string) {
    if (this.route.url.includes(route)) {
      return true;
    }

    return false;
  }
}
