<button
  #button
  appButton
  [appDropdown]="dropdown"
  [offsetY]="8"
  class="flag-button"
  [size]="this.size"
  [stretch]="stretch"
  [class.square]="square"
  (opened)="onOpened()"
  (closed)="onClosed()"
>
  <app-avatar class="flag" imgSrc="assets/images/logos/moovd-logo.png" />

  @if (!square) {
  <div>
    <app-label class="label-name">{{ authService.userId() }}</app-label>
    <app-label class="label-company">Moovd B.V</app-label>
  </div>
  <fa-icon icon="chevron-down" class="chevron" [@indicatorRotate]="isOpen" />
  }
</button>

<ng-template #dropdown>
  <app-dropdown>
    <button appButton [shadow]="true" (click)="signOut()">
      <app-label [icon]="logoutMenu.icon" [gap]="0.5" class="label-setting">{{
        logoutMenu.text | translate
      }}</app-label>
    </button>
  </app-dropdown>
</ng-template>
