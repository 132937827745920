import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthComponent } from '@app/feature/auth/pages/auth/auth.component';

@Component({
  selector: 'app-auth-layout',
  standalone: true,
  imports: [TranslateModule, AuthComponent],
  templateUrl: './auth-layout.component.html',
  styleUrl: './auth-layout.component.scss'
})
export class AuthLayoutComponent {
  readonly version = '1.0.0';
}
