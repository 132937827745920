<app-navbar [shadow]="true">
  <app-language-switch
    class="mr-4"
    size="md"
    [square]="(mobileService.mobile$ | async) === true"
  />
  <app-user-profile-button
    class="mr-4"
    size="md"
    [square]="(mobileService.mobile$ | async) === true"
  />
</app-navbar>
<main>
  <nav class="panel-wrap">
    <aside class="panel-contain">
      <app-sidebar >
        <app-menu-boost></app-menu-boost>
      </app-sidebar>
    </aside>
  </nav>
  <section>
    <router-outlet></router-outlet>
  </section>
</main>
