import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from '@app/shared/layouts/cms-layout/components';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LanguageSwitchComponent } from '@app/shared/components/language-switch/language-switch.component';
import { MobileService } from '@app/core/services';
import { CommonModule } from '@angular/common';
import { UserProfileButtonComponent } from '@app/shared/components/user-profile-button/user-profile-button.component';
import { MenuBoostComponent } from './components/menu-boost/menu-boost.component';

@Component({
  selector: 'app-cms-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SidebarComponent,
    NavbarComponent,
    LanguageSwitchComponent,
    UserProfileButtonComponent,
    MenuBoostComponent
  ],
  templateUrl: './cms-layout.component.html',
  styleUrl: './cms-layout.component.scss',
})
export class CmsLayoutComponent {
  constructor(public mobileService: MobileService){}
}
