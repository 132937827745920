import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from '@components';

@Component({
  selector: 'app-insight',
  standalone: true,
  imports: [RouterModule, BreadcrumbComponent],
  templateUrl: './insight.component.html',
  styleUrl: './insight.component.scss'
})
export class InsightComponent {

}
