import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from '@components';

@Component({
  selector: 'app-hack',
  standalone: true,
  imports: [RouterModule, BreadcrumbComponent],
  templateUrl: './hack.component.html',
  styleUrl: './hack.component.scss'
})
export class HackComponent {

}
