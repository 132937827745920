import { Routes } from '@angular/router';

import { PositiveMessageComponent } from '@app/feature/positive-message/pages/positive-message/positive-message.component';
import { HackComponent } from '@app/feature/hack/pages/hack/hack.component';
import { InsightComponent } from '@app/feature/insight/pages/insight/insight.component';
import { ChallengeComponent } from '@app/feature/challenge/pages/challenge/challenge.component';
import { MilestoneComponent } from '@app/feature/milestone/pages/milestone/milestone.component';

export const CONTENT_MANAGEMENT_ROUTES: Routes = [
  {
    path: 'positive-messages',
    component: PositiveMessageComponent,
    loadChildren: () =>
      import('@app/feature/positive-message/positive-message.routes').then(
        (r) => r.CONTENT_POSITIVE_MESSAGE_ROUTES
      ),
  },
  {
    path: 'hacks',
    component: HackComponent,
    loadChildren: () =>
      import('@app/feature/hack/hack.routes').then(
        (r) => r.CONTENT_HACK_ROUTES
      ),
  },
  {
    path: 'insights',
    component: InsightComponent,
    loadChildren: () =>
      import('@app/feature/insight/insight.routes').then(
        (r) => r.CONTENT_INSIGHT_ROUTES
      ),
  },
  {
    path: 'challenges',
    component: ChallengeComponent,
    loadChildren: () =>
      import('@app/feature/challenge/challenge.routes').then(
        (r) => r.CONTENT_CHALLENGE_ROUTES
      ),
  },
  {
    path: 'milestones',
    component: MilestoneComponent,
    loadChildren: () =>
      import('@app/feature/milestone/milestone.routes').then(
        (r) => r.CONTENT_MILESTONE_ROUTES
      ),
  },
  { path: '', pathMatch: 'full', redirectTo: 'positive-messages' },
  { path: '**', redirectTo: 'positive-messages' },
];
