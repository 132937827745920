import { Component } from '@angular/core';
import { CardComponent } from '@app/shared/components/card/card.component';
import { BadgeComponent } from '@app/shared/components/badge/badge.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MobileService } from '@app/core/services';
import { CommonModule } from '@angular/common';
import { AppSwitchComponent } from '@app/shared/components/app-switch/app-switch.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    BadgeComponent,
    FontAwesomeModule,
    AppSwitchComponent
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  constructor(public mobileService: MobileService) {}
}
