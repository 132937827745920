import { Component, signal } from '@angular/core';
import { FormBase } from '@classes';
import {
  AuthAttributes,
  AuthLoginFormField,
  AuthLoginInterface,
} from '../../models';
import { catchError, map, Observable, of } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { FormComponent, FormFieldComponent, InputComponent, PasswordInputComponent } from '@components';
import { ButtonDirective } from '@directives';
import { AuthService } from '@app/core/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-login',
  standalone: true,
  imports: [
    TranslateModule,
    FormComponent,
    FormFieldComponent,
    InputComponent,
    ButtonDirective,
    PasswordInputComponent
  ],
  templateUrl: './auth-login.component.html',
  styleUrl: './auth-login.component.scss',
})
export class AuthLoginComponent extends FormBase<AuthLoginInterface, unknown> {
  public override form = AuthLoginFormField;
  public errorMessage = signal<string>('email and password are required!');
  public isLoading = signal<boolean>(false);

  constructor(private authService: AuthService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(() => {
      this.errorMessage.set('email and password are required!');
    });
  }

  protected override onSubmit(data: AuthLoginInterface): Observable<unknown> {
    this.isLoading.set(true);

    return this.authService
      .logIn({
        [AuthAttributes.USERNAME]: data.username,
        [AuthAttributes.PASSWORD]: data.password,
      })
      .pipe(
        map(async () => {
          await this.router.navigate(['content-management']);
          this.isLoading.set(false);
        }),
        catchError(() => {
          this.isLoading.set(false);
          this.form.setValue({ username: null, password: null });
          this.errorMessage.set(
            'Incorrect email or password, try again with the correct email or password!'
          );
          return of([]);
        })
      );
  }
}
