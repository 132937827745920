@for (menu of boostMenus; track menu.text) {
<a [href]="menu.url">
  <button
    class="menu-wrap"
    appButton
    [stretch]="true"
    [ngClass]="{ active: isActive(menu.url) }"
  >
    <div class="menu-icon">
      <ng-icon [name]="menu.icon"></ng-icon>
    </div>

    <div class="menu-title">
      <p class="menu-title-text">{{ menu.text | translate }}</p>
    </div>
  </button>
</a>
}
