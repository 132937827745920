import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent, CmsLayoutComponent } from '@layouts';
import { AUTH_ROUTES } from '@app/feature/auth/auth.routes';
import { CONTENT_MANAGEMENT_ROUTES } from '@app/feature/content-management.routes';
import { authGuard, userActiveGuard } from '@guards';

const routes: Routes = [
  {
    path: 'content-management',
    canActivate: [authGuard],
    component: CmsLayoutComponent,
    loadChildren: () => CONTENT_MANAGEMENT_ROUTES,
  },
  {
    path: 'auth',
    canActivate: [userActiveGuard],
    component: AuthLayoutComponent,
    loadChildren: () => AUTH_ROUTES, 
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
