import { Injectable, signal } from '@angular/core';
import { Amplify } from 'aws-amplify';
import outputs from 'amplify_outputs.json';
import { catchError, from, map, Observable, of, tap } from 'rxjs';
import { signIn, getCurrentUser } from 'aws-amplify/auth';
import { AuthLoginInterface } from '@app/feature/auth/models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  
  public loggedIn = signal<boolean>(false);
  public userId = signal<string|null>(null);

  constructor() {
    Amplify.configure(outputs);
  }

  public logIn(data: AuthLoginInterface): Observable<any> {
    return from(signIn({ username: data.username, password: data.password })).pipe(
      tap(() => {
        this.loggedIn.set(true);
      })
    );
  }

  public isAuthenticated(): Observable<boolean> {
    return this.getCurrentUser()
    .pipe(
      map((res:any) => {
        this.userId.set(res.signInDetails.loginId);
        this.loggedIn.set(true);
        return true;
      }),
      catchError(() => {
        this.userId.set(null);
        this.loggedIn.set(false);
        return of(false);
      })
    )
  }

  public getCurrentUser(): Observable<any>{
    return from(getCurrentUser())
  }
}
