<div class="test-a">
  <app-applicaton-switch
    class="mr-4"
    [stretch]="true"
    size="md"
    [square]="(mobileService.mobile$ | async) === true"
  />
</div>

<section class="test-b">
  <ng-content></ng-content>
</section>